import { cn } from 'Utils/Helpers'
import { FullScreenModal } from 'V2Components'
import React from 'react'

function FullScreenContentModal({ children, width = '626px' }) {
  return (
    <FullScreenModal showModal>
      <div className={cn('!bg-[#00000033]', 'h-[100%]', 'flex justify-center items-center')}>
        <div style={{ width }} className={cn('p-5 bg-white rounded-lg')}>
          {children}
        </div>
      </div>
    </FullScreenModal>
  )
}

export { FullScreenContentModal }
